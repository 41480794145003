import React from 'react';

const UserList = ({ users, deleteUser }) => {
  return (
    <ul>
      {users.map((user) => (
        <li key={user.id} className="flex justify-between mb-2">
          <span>{user.name} ({user.email})</span>
          <button
            onClick={() => deleteUser(user.id)}
            className="bg-red-500 text-white p-1 rounded"
          >
            Delete
          </button>
        </li>
      ))}
    </ul>
  );
};

export default UserList;
