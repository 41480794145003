import React, { useEffect, useState } from 'react';
import UserForm from './components/UserForm';
import UserList from './components/UserList';

const App = () => {
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    // const response = await fetch('http://localhost:5000/users');
    const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
const response = await fetch(`${apiUrl}/users`);

    const data = await response.json();
    setUsers(data);
  };

  const addUser = async (user) => {
    const response = await fetch('http://localhost:5000/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    const newUser = await response.json();
    setUsers([...users, newUser]);
  };

  const deleteUser = async (id) => {
    await fetch(`http://localhost:5000/users/${id}`, {
      method: 'DELETE',
    });
    setUsers(users.filter((user) => user.id !== id));
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">User Management</h1>
      <UserForm addUser={addUser} />
      <UserList users={users} deleteUser={deleteUser} />
    </div>
  );
};

export default App;
